<template>
  <v-card>
    <v-list>
      <v-list-group
        v-for="item in list"
        :key="item.id"
        :value="activeGroupId === item.id"
        class="group"
      >
        <template #activator>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </template>

        <v-list-item
          v-for="subitem in item.competences"
          :key="subitem.id"
          lines="three"
          link
          exact-path
          :to="{
            name: routeName,
            params: { id: subitem.id },
          }"
        >
          <v-list-item-content>
            <v-list-item-title class="wrap-text">{{
              subitem.name
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-group>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "CompetenceSidebar",
  props: {
    routeName: {
      type: String,
      default: "competence_tracks",
    },
    activeCompetenceId: {
      type: Number,
    },
  },
  computed: {
    list() {
      return this.$store.getters["groupedCompetences"];
    },
    activeGroupId() {
      return this.$store.state.competences.find(
        (n) => n.id === this.activeCompetenceId
      )?.group_id;
    },
  },
};
</script>

<style scoped>
.wrap-text {
  white-space: normal !important;
}

.group {
  border-bottom: 1px solid #dedede;
}

.group:last-child {
  border-bottom: none;
}
</style>
