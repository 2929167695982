import {
  TASK_STATUS_COMPLETE,
  TASK_STATUS_FAILURE,
  TASK_STATUS_PENDING,
  TASK_STATUS_RUNNING,
} from "@/constants";
/**
 *
 * @param {string} status
 * @returns {{statusText: string, statusIcon: string} | undefined}
 */
export const getStatusData = (status) => {
  if (
    ![
      TASK_STATUS_PENDING,
      TASK_STATUS_RUNNING,
      TASK_STATUS_COMPLETE,
      TASK_STATUS_FAILURE,
    ].includes(status)
  ) {
    return;
  }
  let result = {
    statusText: "",
    statusIcon: "",
  };
  switch (status) {
    case TASK_STATUS_PENDING:
      result.statusText = "В ожидании";
      result.statusIcon = "mdi-update";
      break;
    case TASK_STATUS_RUNNING:
      result.statusText = "В обработке";
      result.statusIcon = "mdi-progress-clock";
      break;
    case TASK_STATUS_COMPLETE:
      result.statusText = "Выполнен";
      result.statusIcon = "mdi-check-all";
      break;
    case TASK_STATUS_FAILURE:
      result.statusText = "Ошибка";
      result.statusIcon = "mdi-alert";
      break;
  }

  return result;
};
